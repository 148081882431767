import { defineNuxtRouteMiddleware, useNuxtApp, navigateTo, abortNavigation } from 'nuxt/app'
import { getFirstUrlByRoles } from '~/constants/menu'

export default defineNuxtRouteMiddleware((to) => {
  const nuxtApp = useNuxtApp()

  if (nuxtApp.$authorization.onlyDocsRole()) {
    return navigateTo({ path: '/docs/' }, { replace: true })
  }

  const firstUrl = getFirstUrlByRoles(false, nuxtApp.$authorization, to.fullPath)
  if (firstUrl === '/') return abortNavigation({ statusCode: 401 })
  return navigateTo({ path: firstUrl }, { replace: true })
})
